import React from 'react';
export default function Loader() {
  return <div style={{ minHeight: "650px", height: "100%", display: "grid" }}>
    <img
      alt="loader"
      style={{
        width: "50%",
        margin: "auto",
        marginTop: "50%"
      }}
      src={process.env.PUBLIC_URL + '/loader.gif'}
    />
  </div>;
}