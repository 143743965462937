import React from "react";

export default function CookiePolicy() {
    return <p>Cookie Policy di bee-id.it<br />
        <br />Questo documento contiene informazioni in merito alle tecnologie che consentono a bee-id.it di raggiungere gli scopi descritti di seguito. Tali tecnologie permettono al Titolare di raccogliere e salvare informazioni (per esempio tramite l’utilizzo di Cookie) o di utilizzare risorse (per esempio eseguendo uno script) sul dispositivo dell’Utente quando quest’ultimo interagisce con bee-id.it.
        <br />
        <br />Per semplicità, in questo documento tali tecnologie sono sinteticamente definite “Strumenti di Tracciamento”, salvo vi sia ragione di differenziare.
        <br />Per esempio, sebbene i Cookie possano essere usati in browser sia web sia mobili, sarebbe fuori luogo parlare di Cookie nel contesto di applicazioni per dispositivi mobili, dal momento che si tratta di Strumenti di Tracciamento che richiedono la presenza di un browser. Per questo motivo, all’interno di questo documento il temine Cookie è utilizzato solo per indicare in modo specifico quel particolare tipo di Strumento di Tracciamento.
        <br />
        <br />Alcune delle finalità per le quali vengono impiegati Strumenti di Tracciamento potrebbero, inoltre richiedere il consenso dell’Utente. Se viene prestato il consenso, esso può essere revocato liberamente in qualsiasi momento seguendo le istruzioni contenute in questo documento.
        <br />
        <br />bee-id.it utilizza Strumenti di Tracciamento gestiti direttamente dal Titolare (comunemente detti Strumenti di Tracciamento “di prima parte”) e Strumenti di Tracciamento che abilitano servizi forniti da terzi (comunemente detti Strumenti di Tracciamento “di terza parte”). Se non diversamente specificato all’interno di questo documento, tali terzi hanno accesso ai rispettivi Strumenti di Tracciamento.
        <br />Durata e scadenza dei Cookie e degli altri Strumenti di Tracciamento simili possono variare a seconda di quanto impostato dal Titolare o da ciascun fornitore terzo. Alcuni di essi scadono al termine della sessione di navigazione dell’Utente.
        <br />In aggiunta a quanto specificato nella descrizione di ciascuna delle categorie di seguito riportate, gli Utenti possono ottenere informazioni più dettagliate ed aggiornate sulla durata, così come qualsiasi altra informazione rilevante - quale la presenza di altri Strumenti di Tracciamento - nelle privacy policy dei rispettivi fornitori terzi (tramite i link messi a disposizione) o contattando il Titolare.
        <br />
        <br />bee-id.it utilizza Cookie comunemente detti “tecnici” o altri Strumenti di Tracciamento analoghi per svolgere attività strettamente necessarie a garantire il funzionamento o la fornitura del Servizio.
        <br />Attività strettamente necessarie a garantire il funzionamento di bee-id.it e la fornitura del Servizio
        <br />
        <br />Altre attività che prevedono l’utilizzo di Strumenti di Tracciamento
        <br />Miglioramento dell’esperienza
        <br />bee-id.it utilizza Strumenti di Tracciamento per fornire una user experience personalizzata, consentendo una migliore gestione delle impostazioni personali e l'interazione con network e piattaforme esterne.
        <br />
        <br />Interazione con social network e piattaforme esterne
        <br />Questo tipo di servizi permette di effettuare interazioni con i social network, o con altre piattaforme esterne, direttamente dalle pagine di bee-id.it.
        <br />Le interazioni e le informazioni acquisite da bee-id.it sono in ogni caso soggette alle impostazioni privacy dell’Utente relative ad ogni social network.
        <br />Questo tipo di servizio potrebbe comunque raccogliere dati sul traffico per le pagine dove il servizio è installato, anche quando gli Utenti non lo utilizzano.
        <br />Si raccomanda di disconnettersi dai rispettivi servizi per assicurarsi che i dati elaborati su bee-id.it non vengano ricollegati al profilo dell'Utente.
        <br />
        <br />Pulsante Mi Piace e widget sociali di Facebook (Facebook, Inc.)
        <br />Il pulsante “Mi Piace” e i widget sociali di Facebook sono servizi di interazione con il social network Facebook, forniti da Facebook, Inc.
        <br />Dati Personali trattati: Cookie e Dati di utilizzo.
        <br />
        <br />Luogo del trattamento: Stati Uniti – Privacy Policy.
        <br />
        <br />Pulsante Tweet e widget sociali di Twitter (Twitter, Inc.)
        <br />Il pulsante Tweet e i widget sociali di Twitter sono servizi di interazione con il social network Twitter, forniti da Twitter, Inc.
        <br />Dati Personali trattati: Cookie e Dati di utilizzo.
        <br />
        <br />Luogo del trattamento: Stati Uniti – Privacy Policy.
        <br />
        <br />Pulsante e widget sociali di Linkedin (LinkedIn Corporation)
        <br />Il pulsante e i widget sociali di LinkedIn sono servizi di interazione con il social network Linkedin, forniti da LinkedIn Corporation.
        <br />Dati Personali trattati: Cookie e Dati di utilizzo.
        <br />
        <br />Luogo del trattamento: Stati Uniti – Privacy Policy.
        <br />
        <br />Misurazione
       <br /> <br />bee-id.it utilizza Strumenti di Tracciamento per misurare il traffico e analizzare il comportamento degli Utenti con l'obiettivo di migliorare il Servizio.
        <br />
        <br />Heat mapping e registrazione sessioni
        <br />I servizi di heat mapping sono utilizzati per individuare quali aree di una pagina sono oggetto del passaggio del cursore o di click del mouse in modo da rilevare quali di esse attraggono il maggior interesse. Questi servizi permettono di monitorare e analizzare i dati di traffico e servono a tener traccia del comportamento dell’Utente.
        <br />Alcuni di questi servizi potrebbero registrare le sessioni e renderle disponibili per visualizzarle in seguito.
        <br />
        <br />Hotjar Heat Maps & Recordings (Hotjar Ltd.)
        <br />Hotjar è un servizio di heat mapping e di registrazione delle sessioni fornito da Hotjar Ltd.
       <br /> Hotjar rispetta gli header generici „Do Not Track”. Questo significa che il browser può indicare allo script di non raccogliere alcun dato dell’Utente. Si tratta di un'impostazione che è disponibile in tutti i principali browser. Maggiori Informazioni sull'opt-out da Hotjar sono disponibili qui.
        <br />Dati Personali trattati: Cookie, Dati di utilizzo e varie tipologie di Dati secondo quanto specificato dalla privacy policy del servizio.
        <br />
        <br />Luogo del trattamento: Malta – Privacy Policy – Opt Out.
        <br />
        <br />Servizi di statistica gestiti direttamente da bee-id.it
        <br />I servizi in questa sezione permettono al Titolare di elaborare e gestire statistiche grazie all’uso di Strumenti di Tracciamento di prima parte.
        <br />
        <br />Statistiche raccolte in modo diretto (bee-id.it)
        <br />bee-id.it utilizza un sistema di statistiche interno, che non coinvolge terze parti.
        <br />Dati Personali trattati: Cookie e Dati di utilizzo.
        <br />
        <br />Matomo (bee-id.it)
        <br />Matomo è un software di statistica utilizzato da bee-id.it per analizzare i dati in maniera diretta e senza l’ausilio di terzi.
        <br />Dati Personali trattati: Dati di utilizzo e Strumento di Tracciamento.
        <br />
        <br />Statistica
        <br />I servizi contenuti nella presente sezione permettono al Titolare del Trattamento di monitorare e analizzare i dati di traffico e servono a tener traccia del comportamento dell’Utente.
        <br />
        <br />Google Analytics
        <br />Google Analytics è un servizio di analisi web fornito da Google LLC oppure da Google Ireland Limited, a seconda della posizione in cui bee-id.it viene utilizzata, (“Google”). Google utilizza i Dati Personali raccolti allo scopo di tracciare ed esaminare l’utilizzo di bee-id.it, compilare report e condividerli con gli altri servizi sviluppati da Google.
        <br />Google potrebbe utilizzare i Dati Personali per contestualizzare e personalizzare gli annunci del proprio network pubblicitario.
        <br />Dati Personali trattati: Cookie e Dati di utilizzo.
        <br />
        <br />Luogo del trattamento: Stati Uniti – Privacy Policy – Opt Out; Irlanda – Privacy Policy – Opt Out.
        <br />
        <br />Hotjar Form Analysis & Conversion Funnels (Hotjar Ltd.)
        <br />Hotjar è un servizio di statistica fornito da Hotjar Ltd.
        <br />Hotjar rispetta gli header generici „Do Not Track”. Questo significa che il browser può indicare allo script di non raccogliere alcun dato dell’Utente. Si tratta di un'impostazione che è disponibile in tutti i principali browser. Maggiori Informazioni sull'opt-out da Hotjar sono disponibili qui.
        <br />Dati Personali trattati: Cookie e Dati di utilizzo.
        <br />
        <br />Luogo del trattamento: Malta – Privacy Policy – Opt Out.
        <br />
        <br />Rapporti sui dati demografici e sugli interessi di Google Analytics (Google Ireland Limited)
        <br />Rapporti sui dati demografici e sugli interessi di Google Analytics è una funzionalità di generazione dei rapporti sulla pubblicità che rende disponibili i Dati demografici e di interesse all'interno di Google Analytics per bee-id.it (per Dati demografici si intendono i Dati su età e sesso). Gli Utenti possono scegliere di non utilizzare i cookie di Google visitando le [Impostazioni annunci] (https://adssettings.google.com/authenticated) di Google.
        <br />Dati Personali trattati: Cookie e identificatori univoci di dispositivi per la pubblicità (Google Advertiser ID o identificatore IDFA, per esempio).
        <br />
        <br />Luogo del trattamento: Irlanda – Privacy Policy – Opt Out.
        <br />
        <br />Estensione User ID per Google Analytics (Google Ireland Limited)
        <br />Google Analytics su bee-id.it utilizza una funzione chiamata User ID. Questa permette un tracciamento più accurato degli Utenti assegnando ad ognuno un ID unico per varie sessioni e dispositivi, ma in modo tale da non permettere a Google di identificare in maniera personale un individuo o identificare in modo permanente uno specifico dispositivo.
       <br /> L<br />’estensione User ID permette inoltre di connettere Dati provenienti da Google Analytics con altri Dati relativi all’utente raccolti da bee-id.it.
        <br />Il link di Opt Out fornito di seguito consente di disattivare il tracciamento per il dispositivo che stai utilizzando, ma non esclude ulteriori attività di tracciamento effettuate dal Titolare. Per disattivare anche queste ultime, contatta il titolare tramite l’indirizzo email di contatto.
       <br /> Dati Personali trattati: Cookie.

       <br /> Luogo del trattamento: Irlanda – Privacy Policy – Opt Out.
       <br />
        <br /> Funzionalità di generazione dei rapporti sulla pubblicità in Google Analytics (Google Ireland Limited)
       <br /> Google Analytics su bee-id.it ha attivato le funzionalità di generazione dei rapporti sulla pubblicità, che raccolgono informazioni aggiuntive dal cookie DoubleClick (attività web) e dagli ID pubblicitari del dispositivo (attività dell'applicazione). Questo permette al Titolare di analizzare specifici Dati relativi ai comportamenti e agli interessi degli Utenti (Dati sul traffico e Dati di interazione con gli annunci da parte degli Utenti) e, se abilitati, Dati demografici (informazioni su età e sesso). Gli Utenti possono scegliere di non utilizzare i cookie di Google visitando le [Impostazioni annunci] (https://adssettings.google.com/authenticated) di Google.
       <br /> Dati Personali trattati: Cookie, identificatori univoci di dispositivi per la pubblicità (Google Advertiser ID o identificatore IDFA, per esempio) e varie tipologie di Dati secondo quanto specificato dalla privacy policy del servizio.
       <br />
        <br /> Luogo del trattamento: Irlanda – Privacy Policy – Opt Out.
       <br />
        <br /> Targeting e Pubblicità
       <br /> bee-id.it utilizza Strumenti di Tracciamento per fornire contenuti commerciali personalizzati in base al comportamento dell'Utente e per gestire, diffondere e tracciare annunci pubblicitari.
       <br />
        <br /> Remarketing e behavioral targeting
       <br /> Questo tipo di servizi consente a bee-id.it ed ai suoi partner di comunicare, ottimizzare e servire annunci pubblicitari basati sull'utilizzo passato di bee-id.it da parte dell'Utente.
       <br /> Questa attività è facilitata dal tracciamento dei Dati di Utilizzo e dall'utilizzo di Strumenti di Tracciamento per raccogliere informazioni che vengono poi trasferite ai partner che gestiscono le attività di remarketing e di behavioral targeting.
       <br /> Alcuni servizi offrono un'opzione di remarketing basata sulle liste di indirizzi email.
      <br />  In aggiunta alle funzionalità di opt-out offerte dai servizi di seguito riportati, l'Utente può effettuare l’opt-out visitando la pagina di opt-out della Network Advertising Initiative.
      <br />
        <br />  Gli Utenti possono anche scegliere di non partecipare a determinate funzionalità pubblicitarie attraverso le corrispondenti opzioni di configurazione del dispositivo, come le opzioni di configurazione pubblicitaria del dispositivo mobile o la configurazione pubblicitaria generica.
      <br />
        <br />  Facebook Remarketing (Facebook, Inc.)
      <br />  Facebook Remarketing è un servizio di remarketing e behavioral targeting fornito da Facebook, Inc. che collega l'attività di bee-id.it con il network di advertising Facebook.
      <br />  Dati Personali trattati: Cookie e Dati di utilizzo.
      <br />
        <br />  Luogo del trattamento: Stati Uniti – Privacy Policy – Opt Out.
      <br />
        <br />  Pubblico personalizzato di Facebook (Facebook, Inc.)
      <br />  Pubblico personalizzato di Facebook è un servizio di remarketing e di targeting comportamentale fornito da Facebook, Inc. che collega l'attività di bee-id.it con la rete pubblicitaria di Facebook. Gli Utenti possono scegliere di non utilizzare i cookie di Facebook per la personalizzazione degli annunci visitando questa pagina di opt-out.
     <br />   Dati Personali trattati: Cookie e email.
      <br />
        <br />  Luogo del trattamento: Stati Uniti – Privacy Policy – Opt Out.
     <br />
        <br />  Come gestire le preferenze e prestare o revocare il consenso
      <br />  Esistono vari modi per gestire le preferenze relative agli Strumenti di Tracciamento e per prestare o revocare il consenso, ove necessario:
     <br />
        <br />   Gli Utenti possono gestire le preferenze relative agli Strumenti di Tracciamento direttamente tramite le impostazioni dei propri dispositivi - per esempio, possono impedire l’uso o l’archiviazione di Strumenti di Tracciamento.
     <br />
        <br />   In aggiunta, ogni qualvolta l’utilizzo di Strumenti di Tracciamento dipenda da consenso, l’Utente può prestare o revocare tale consenso impostando le proprie preferenze all’interno dell’informativa sui cookie o aggiornando tali preferenze tramite il widget delle impostazioni di tracciamento, se presente.
     <br />
        <br />   Grazie ad apposite funzioni del browser o del dispositivo è anche possibile rimuovere Strumenti di Tracciamento precedentemente salvati.
     <br />
        <br />   Altri Strumenti di Tracciamento presenti nella memoria locale del browser possono essere rimossi cancellando la cronologia di navigazione.
     <br />
        <br />   Per quanto riguarda Strumenti di Tracciamento di terza parte, gli Utenti possono gestire le preferenze e revocare il consenso visitando il relativo link di opt out (qualora disponibile), utilizzando gli strumenti descritti nella privacy policy della terza parte o contattandola direttamente.
     <br />
        <br />   Individuare le impostazioni relative agli Strumenti di Tracciamento
     <br />   Gli Utenti possono, per esempio, trovare informazioni su come gestire i Cookie in alcuni dei browser più diffusi ai seguenti indirizzi:
     <br />
        <br />   Google Chrome
     <br />   Mozilla Firefox
     <br />   Apple Safari
     <br />   Microsoft Internet Explorer
      <br />  Microsoft Edge
     <br />   Brave
     <br />   Opera
        Gli Utenti possono inoltre gestire alcuni Strumenti di Tracciamento per applicazioni mobili disattivandoli tramite le apposite impostazioni del dispositivo, quali le impostazioni di pubblicità per dispositivi mobili o le impostazioni relative al tracciamento in generale (gli Utenti possono consultare le impostazioni del dispositivo per individuare quella pertinente).
<br />
        <br />   Iniziative dell'industria pubblicitaria per la gestione dei consensi
        Fermo restando quanto precede, si informano gli Utenti della possibilità di avvalersi delle informazioni presenti su YourOnlineChoices (EU), Network Advertising Initiative (USA) e Digital Advertising Alliance (USA), DAAC (Canada), DDAI (Giappone) o altri servizi analoghi. Con questi servizi è possibile gestire le preferenze di tracciamento della maggior parte degli strumenti pubblicitari. Il Titolare, pertanto, consiglia agli Utenti di utilizzare tali risorse in aggiunta alle informazioni fornite nel presente documento.
     <br />
        La Digital Advertising Alliance mette inoltre a disposizione un’applicazione chiamata AppChoices che aiuta gli Utenti a controllare la pubblicità comportamentale sulle applicazioni mobili.
     <br />
        Titolare del Trattamento dei Dati
     <br />   Neri Labels srl
     <br /> via Pian della Fonda 7 A/B
     <br />  50031 Barberino di Mugello (FI)
     <br />P.IVA: 01371260488
     <br />
        <br />        Indirizzo email del Titolare: support@bee-id.com
     <br />
        <br />        Dal momento che l’uso di Strumenti di Tracciamento di terza parte su bee-id.it non può essere completamente controllato dal Titolare, ogni riferimento specifico a Strumenti di Tracciamento di terza parte è da considerarsi indicativo. Per ottenere informazioni complete, gli Utenti sono gentilmente invitati a consultare la privacy policy dei rispettivi servizi terzi elencati in questo documento.
     <br />
        <br />       Data l'oggettiva complessità di identificazione delle tecnologie di tracciamento, gli Utenti sono invitati a contattare il Titolare qualora volessero ricevere ulteriori informazioni in merito all'utilizzo di tali tecnologie su bee-id.it.
     <br />
        <br />        Definizioni e riferimenti legali
     <br />        Dati Personali (o Dati)
     <br />        Costituisce dato personale qualunque informazione che, direttamente o indirettamente, anche in collegamento con qualsiasi altra informazione, ivi compreso un numero di identificazione personale, renda identificata o identificabile una persona fisica.
        <br />
        <br />        Dati di Utilizzo
<br />        <br />        Sono le informazioni raccolte automaticamente attraverso bee-id.it (anche da applicazioni di parti terze integrate in bee-id.it), tra cui: gli indirizzi IP o i nomi a dominio dei computer utilizzati dall’Utente che si connette con bee-id.it, gli indirizzi in notazione URI (Uniform Resource Identifier), l’orario della richiesta, il metodo utilizzato nell’inoltrare la richiesta al server, la dimensione del file ottenuto in risposta, il codice numerico indicante lo stato della risposta dal server (buon fine, errore, ecc.) il paese di provenienza, le caratteristiche del browser e del sistema operativo utilizzati dal visitatore, le varie connotazioni temporali della visita (ad esempio il tempo di permanenza su ciascuna pagina) e i dettagli relativi all’itinerario seguito all’interno dell’Applicazione, con particolare riferimento alla sequenza delle pagine consultate, ai parametri relativi al sistema operativo e all’ambiente informatico dell’Utente.

<br />       Utente
        <br />       L'individuo che utilizza bee-id.it che, salvo ove diversamente specificato, coincide con l'Interessato.

        <br />       Interessato
        <br />       La persona fisica cui si riferiscono i Dati Personali.

        <br />        Responsabile del Trattamento (o Responsabile)
        <br />        La persona fisica, giuridica, la pubblica amministrazione e qualsiasi altro ente che tratta dati personali per conto del Titolare, secondo quanto esposto nella presente privacy policy.
        <br />
        <br />      Titolare del Trattamento (o Titolare)
        <br />       La persona fisica o giuridica, l'autorità pubblica, il servizio o altro organismo che, singolarmente o insieme ad altri, determina le finalità e i mezzi del trattamento di dati personali e gli strumenti adottati, ivi comprese le misure di sicurezza relative al funzionamento ed alla fruizione di bee-id.it. Il Titolare del Trattamento, salvo quanto diversamente specificato, è il titolare di bee-id.it.

        <br />   bee-id.it (o questa Applicazione)
        <br />      Lo strumento hardware o software mediante il quale sono raccolti e trattati i Dati Personali degli Utenti.

        <br />      Servizio
        <br />      Il Servizio fornito da bee-id.it così come definito nei relativi termini (se presenti) su questo sito/applicazione.

        <br />     Unione Europea (o UE)
        <br />     Salvo ove diversamente specificato, ogni riferimento all’Unione Europea contenuto in questo documento si intende esteso a tutti gli attuali stati membri dell’Unione Europea e dello Spazio Economico Europeo.

        <br />     Cookie
        <br />      I Cookie sono Strumenti di Tracciamento che consistono in piccole porzioni di dati conservate all'interno del browser dell'Utente.

        <br />     Strumento di Tracciamento
        <br />     Per Strumento di Tracciamento s’intende qualsiasi tecnologia - es. Cookie, identificativi univoci, web beacons, script integrati, e-tag e fingerprinting - che consenta di tracciare gli Utenti, per esempio raccogliendo o salvando informazioni sul dispositivo dell’Utente.

        <br />      Riferimenti legali
        <br />      La presente informativa privacy è redatta sulla base di molteplici ordinamenti legislativi, inclusi gli artt. 13 e 14 del Regolamento (UE) 2016/679.
        <br />
        <br />       Ove non diversamente specificato, questa informativa privacy riguarda esclusivamente bee-id.it.
        <br />
        <br />       Ultima modifica: 11 febbraio 2021
        <br />
        <br />      iubenda ospita questo contenuto e raccoglie solo i Dati Personali strettamente necessari alla sua fornitura.

        <br />      Visualizza la Privacy Policy Completa</p>;
};