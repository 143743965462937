import React, { Suspense, lazy } from "react";
import "./App.css";
import Loader from "./Loader.js";
import { BrowserRouter as Router, Route } from "react-router-dom";
import CookiePolicy from "./CookiePolicy";
const GeneralControllerTally = lazy(() => import('./GeneralControllerTally.js'));
function App() {
  return (
    <div className="tally">
      <div className="" style={{ backgroundColor: "white" }}>
        <Router>
          <Suspense fallback={<Loader/>}>
            <Route exact path="/:slang/:idTag/:idProdotto" component={GeneralControllerTally} />
          </Suspense>
          <Route exact path="/privacy-policy" render={() => window.location.replace("https://www.iubenda.com/privacy-policy/21145215/full-legal")} />
          <Route exact path="/cookie-policy" component={CookiePolicy} />
        </Router>
      </div>
    </div>
  );
}
export default App;
